<template>
	<section>
		<b-form>
			<b-card
				class="mb-0"
				no-body
			>
				<b-row>
					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="name">
							<strong>MONEDA</strong>
						</label>
						<v-select
							id="moneda"
							:value="formData.currency_type"
							:options="options.currencyTypes"
							disabled
							readonly
						/>
					</b-col>

					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="tipo">
							<strong>TIPO</strong>
						</label>
						<v-select
							id="tipo"
							:value="formData.indicator"
							:options="options.indicators"
							disabled
							readonly
						/>
					</b-col>

					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="gasto">
							<strong>GASTO GENERAL (GG %)</strong>
						</label>
						<b-form-input
							id="gasto"
							:value="formData.general_expenses"
							disabled
							readonly
							trim
						/>
					</b-col>

					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="profit">
							<strong>UTILIDAD (UTI %)</strong>
						</label>
						<b-form-input
							id="profit"
							v-model="formData.profit"
							disabled
							readonly
						/>
					</b-col>

					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="tc">
							<strong>TC de SS</strong>
						</label>
						<b-form-input
							id="tc"
							:value="formData.exchange_rate"
							disabled
							readonly
						/>
					</b-col>

					<b-col
						cols="12"
						md="4"
						class="mb-1"
					>
						<label for="moneda_ss">
							<strong>MONEDA SS</strong>
						</label>

						<b-form-input
							id="moneda_ss"
							:value="formData.exchange_sign"
							readonly
							disabled
						/>
					</b-col>

					<b-col
						cols="12"
						v-if="formData.file_url"
					>
						<b-icon-file
							:filename="formData.original_name"
							:file-url="formData.file_url"
						/>
					</b-col>

					<b-col
						cols="12"
						class="mt-1"
					>
						<hr />
					</b-col>

					<b-col cols="12">
						<b-card
							no-body
							class="card-statistics mb-0"
						>
							<b-card-body class="statistics-body">
								<b-row>
									<b-col
										v-for="item in statisticsItems"
										:key="item.icon"
										md="3"
										sm="6"
										class="mb-2 mb-md-0"
										:class="item.customClass"
									>
										<b-media no-body>
											<b-media-aside class="mr-2">
												<b-avatar
													size="48"
													:variant="item.color"
												>{{ item.sign }}</b-avatar>
											</b-media-aside>
											<b-media-body class="my-auto">
												<h4 class="font-weight-bolder mb-0">{{ formatCurrency(item.amount) }}</h4>
												<b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
											</b-media-body>
										</b-media>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
				</b-row>
			</b-card>
		</b-form>
	</section>
</template>
  
<script>
import { ref, onMounted, onUnmounted } from "@vue/composition-api"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import tabEconomicStoreModule from "./tabEconomicStoreModule"
import useNotifications from "@notifications"
import { formatCurrency } from "@core/utils/filter"
import vSelect from "vue-select"
import axios from "@axios"
import store from "@/store"

export default {
	components: {
		BIconFile,

		vSelect
	},
	props: {
		requestId: {
			type: Number,
			require: true
		},
		requestEconomicId: {
			type: Number,
			require: true
		}
	},
	setup(props, { emit }) {
		const { toastNotification } = useNotifications()

		//REFS
		const formData = ref({})
		const refEconomicForm = ref(null)
		const file = ref(null)
		const options = ref({})
		const statisticsItems = ref([
			{
				color: "light-primary",
				sign: "S/",
				amount: 0,
				subtitle: "COSTO DIRECTO",
				customClass: "mb-2 mb-xl-0"
			},
			{
				color: "light-info",
				amount: 0,
				sign: "S/",
				subtitle: "GASTO GENERAL",
				customClass: "mb-2 mb-xl-0"
			},
			{
				sign: "S/",
				color: "light-danger",
				amount: 0,
				subtitle: "UTILIDAD",
				customClass: "mb-2 mb-sm-0"
			},
			{
				sign: "S/",
				color: "light-success",
				amount: 0,
				subtitle: "IMPORTE TOTAL",
				customClass: ""
			}
		])

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-economic"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				tabEconomicStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-request-economic/fetchRequestEconomicTemplate", {
					id: props.requestEconomicId
				})
				.then((response) => {
					setData(response.data.economicData)
					emit("update-description", response.data.economicData.description)
				})
				.catch((error) => {
					const message = error.message || error.response?.data?.message
					toastNotification(message, false)
				})
		}

		const setData = (data) => {
			const indicator =
				options.value.indicators.find(
					(item) => item.value === data.indicator
				) || null
			const currency_type =
				options.value.currencyTypes.find(
					(item) => item.value === data.currency_type_id
				) || null

			formData.value = {
				file_id: data.file_id,
				original_name: data.original_name,
				file_url: data.file_url,
				currency_type,
				indicator,
				exchange_rate: data.exchange_rate,
				general_expenses: data.general_expenses
					? (parseFloat(data.general_expenses) * 100).toFixed(2)
					: 0,
				profit: data.profit ? (parseFloat(data.profit) * 100).toFixed(2) : 0,
				subtotal: data.subtotal ? parseFloat(data.subtotal) : 0,
				exchange_sign: currency_type?.sign
			}
			calculateStatistics()
		}

		onMounted(async () => {
			await getSelectFilters()
			getData()
		})

		const getSelectFilters = async () => {
			const response = await axios.get("/selectors/currency-types")

			options.value = { ...response.data }
			options.value.indicators = [
				{
					label: "PRECIO UNITARIO (PxQ)",
					value: "PRECIO UNITARIO (PxQ)"
				},
				{
					label: "SUMA ALZADA",
					value: "SUMA ALZADA"
				}
			]
		}

		const calculateStatistics = () => {
			statisticsItems.value[0].amount = formData.value.subtotal
			statisticsItems.value[1].amount =
				formData.value.subtotal * (formData.value.general_expenses / 100)
			statisticsItems.value[2].amount =
				formData.value.subtotal * (formData.value.profit / 100)

			statisticsItems.value[3].amount =
				statisticsItems.value[0].amount +
				statisticsItems.value[1].amount +
				statisticsItems.value[2].amount
		}

		return {
			//REFS
			refEconomicForm,
			formData,
			statisticsItems,
			options,
			file,

			//METHODS
			calculateStatistics,

			//HELPERS
			formatCurrency
		}
	}
}
</script>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>