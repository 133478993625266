<template>
	<section>
		<b-row>
			<b-col cols="12">
				<b-alert
					variant="info"
					show
				>
					<h6 class="alert-heading">
						<ul class="mb-0 pl-1">
							<li>
								<span class="small">La suma total de los pesos de las propuesta debe ser 100%</span>
							</li>
							<li>
								<span
									class="small"
								>La suma total de las categorías seleccionadas debe ser igual al peso de la propuesta</span>
							</li>
						</ul>
					</h6>
				</b-alert>
			</b-col>

			<b-col cols="12">
				<app-collapse
					type="margin"
					v-if="proposalWeightings.length > 0"
				>
					<app-collapse-item :title="proposalWeightings[0].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[0].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[0].weight }}</strong>
							</span>
						</template>

						<div
							v-if="!formData.economic.description"
							class="text-muted"
						>Sin descripción</div>

						<div
							v-else
							v-html="formData.economic.description"
						></div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[1].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[1].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[1].weight }}</strong>
							</span>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.sigDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required"
								disabled
								readonly
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weight || 0 }}</span>
						</div>
					</app-collapse-item>

					<app-collapse-item :title="proposalWeightings[2].proposal_name">
						<template #header>
							<span class="lead collapse-title">
								<strong>{{ proposalWeightings[2].proposal_name }}</strong>
							</span>
							<span class="text-primary small ml-1">
								<strong>{{ proposalWeightings[2].weight }}</strong>
							</span>
						</template>

						<div
							class="d-flex align-items-center mb-1"
							v-for="(document, index) in formData.tecDocuments"
							:key="index"
						>
							<b-form-checkbox
								name="technical_documents"
								:checked="document.is_required"
								disabled
								readonly
							>
								<span
									class="small"
									:class="{'text-muted': !document.is_required }"
								>{{ document.name }}</span>
							</b-form-checkbox>
							<span class="small text-info ml-1">{{ document.weight || 0 }}</span>
						</div>
					</app-collapse-item>
				</app-collapse>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { ref, onUnmounted, onMounted } from "@vue/composition-api"
import Ripple from "vue-ripple-directive"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import tabRequirementStoreModule from "./tabRequirementStoreModule"
import useNotifications from "@notifications"
import store from "@/store"

export default {
	components: {
		AppCollapse,
		AppCollapseItem
	},
	directives: {
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			require: true
		},
		economicProposal: {
			type: Object,
			default: () => ({}),
			require: true
		}
	},
	setup(props) {
		const { toastNotification } = useNotifications()

		const SIG_ID = 2
		const TEC_ID = 3
		//REFS
		const proposalWeightings = ref([])
		const economicProposal = ref(props.economicProposal)
		const formData = ref({
			economic: {},
			sigDocuments: [],
			tecDocuments: []
		})
		const weight = ref(null)

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-base"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				tabRequirementStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-request-base/fetchRequestProposalDocuments", {
					request_id: props.requestId
				})
				.then((response) => {
					setDocumentsData(response.data)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})

			store
				.dispatch("app-request-base/fetchRequestProposalWeightings", {
					request_id: props.requestId
				})
				.then((response) => {
					setWeightingsData(response.data)
				})
				.catch((error) => {
					toastNotification(error.response.data.message, false)
				})
		}

		const setDocumentsData = (data) => {
			const sigDocuments = []
			const tecDocuments = []

			data.documents.forEach((item) => {
				if (item.proposal_type_id === SIG_ID) {
					sigDocuments.push(item)
				}
				if (item.proposal_type_id === TEC_ID) {
					tecDocuments.push(item)
				}
			})

			formData.value.economic = economicProposal.value
			formData.value.sigDocuments = sigDocuments
			formData.value.tecDocuments = tecDocuments
		}
		const setWeightingsData = (data) => {
			proposalWeightings.value = data.proposalWeightings
		}

		onMounted(() => {
			getData()
		})

		return {
			//REFS
			formData,
			weight,

			//METHODS,
			proposalWeightings
		}
	}
}
</script>