<template>
	<section class="mt-0">
		<b-row>
			<b-col
				cols="12"
				v-if="usersAssigned"
			>
				<statistic-card-horizontal
					icon="UsersIcon"
					color="info"
					:statistic="usersAssigned"
					statistic-title="Analista asignado para la licitación"
				/>
			</b-col>

			<b-col cols="12">
				<b-card class="p-0">
					<b-row>
						<b-col
							cols="12"
							class="mb-2"
						>
							<b-table
								class="table-sm"
								ref="refApprovalsTable"
								:fields="fields"
								:items="items"
								empty-text="No se encontraron registros coincidentes"
								show-empty
								responsive
								bordered
								fiexed
							>
								<template #cell(status)="data">
									<b-badge
										class="text-nowrap"
										:variant="data.item.status === true ? 'light-success' : data.item.status === false ? 'light-danger' : 'light-warning'"
										pill
									>{{ data.item.status === true ? 'APROBADO' : data.item.status === false ? 'RECHAZADO' : 'PENDIENTE' }}</b-badge>
								</template>

								<template #cell(approver_fullname)="data">
									<span class="text-nowrap">{{ data.item.approver_fullname || '-' }}</span>
								</template>

								<template #cell(approved_at)="data">
									<span class="text-nowrap">{{ formatStringToDate(data.item.approved_at) }}</span>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</b-card>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import { formatStringToDate } from "@core/utils/filter"
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"
import { ref, onMounted } from "@vue/composition-api"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import axios from "@axios"

export default {
	components: {
		AppCollapse,
		AppCollapseItem,
		StatisticCardHorizontal
	},
	directives: {
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		const refApprovalsTable = ref(null)
		const fields = ref([])
		const items = ref([])
		const usersAssigned = ref("")

		const setData = (data) => {
			const styleColumns = { thClass: "text-center", tdClass: "text-center" }

			fields.value = [
				{ key: "level_name", label: "Tipo de Aprobación", ...styleColumns },
				{ key: "status", label: "Estado", ...styleColumns },
				{
					key: "approver_fullname",
					label: "aprobador",
					thClass: styleColumns.thClass
				},
				{ key: "approved_at", label: "Fecha Registro", ...styleColumns }
			]

			items.value = data.items
			usersAssigned.value = data.usersAssigned
		}

		const getData = async () => {
			try {
				const payload = { request_id: props.requestId }
				const response = await axios.get(`/request-approvals/`, {
					params: payload
				})
				setData(response.data.requestApprovals)
			} catch (error) {
				toastNotification(error.response?.data?.message, false)
			}
		}

		onMounted(async () => {
			await getData()
		})

		return {
			refApprovalsTable,
			items,
			fields,
			usersAssigned,

			formatStringToDate
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>