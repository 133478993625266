import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchRequestProposalDocuments(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/request-proposal-documents', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		fetchRequestProposalWeightings(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/request-proposal-weightings', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
	}
}