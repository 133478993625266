<template>
	<section class="mt-0">
		<!-- Table Container Card -->
		<b-card class="p-0">
			<b-row>
				<b-col
					class="mb-2"
					cols="12"
				>
					<!-- Table Top -->
					<b-row>
						<!-- Per Page -->
						<b-col
							cols="12"
							md="6"
							class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
						>
							<label>Mostrar</label>
							<v-select
								v-model="perPage"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="perPageOptions"
								:clearable="false"
								class="per-page-selector d-inline-block mx-50"
							/>
							<label>registros</label>
						</b-col>

						<!-- Search -->
						<b-col
							cols="12"
							md="6"
							class="ml-auto"
						>
							<div class="d-flex">
								<b-form-input
									v-model="searchQuery"
									class="d-inline-block mr-1"
									placeholder="Buscar ..."
									trim
								/>
							</div>
						</b-col>
					</b-row>
				</b-col>

				<b-col cols="12">
					<b-table
						ref="refBiddersListTable"
						class="position-relative"
						:style="totalBidders === 1 ? 'height: 200px' : 'height: inherit'"
						:items="fetchBiddersValidation"
						responsive
						:fields="tableColumns"
						primary-key="id"
						:sort-by.sync="sortBy"
						show-empty
						empty-text="No se encontraron registros coincidentes"
						:sort-desc.sync="isSortDirDesc"
					>
						<template #table-busy>
							<div class="text-center text-primary my-2">
								<b-spinner class="align-middle mb-1"></b-spinner>
								<div>
									<b>CARGANDO</b>
								</div>
							</div>
						</template>

						<template #head(competition_state_name)="data">
							<span v-html="data.label"></span>
						</template>

						<template #head(answer)="data">
							<span v-html="data.label"></span>
						</template>

						<!-- Column: User -->
						<template #cell(bidder_name)="data">
							<b-media vertical-align="center">
								<template #aside>
									<b-avatar
										size="32"
										:src="null"
										:text="avatarText(data.item.bidder_name)"
										variant="light-success"
									/>
								</template>
								<span class="font-weight-bold d-block text-nowrap">{{ data.item.bidder_name }}</span>
								<div class="small text-muted">{{ data.item.bidder_ruc }}</div>
							</b-media>
						</template>

						<template #cell(bidder_email)="data">
							<div class="text-nowrap">{{ data.item.bidder_email }}</div>
							<div class="small text-muted">{{ data.item.bidder_phone }}</div>
						</template>

						<template #cell(competition_state_name)="data">
							<div class="text-nowrap">
								<b-badge
									:variant="`light-${ data.item.competition_state_name ? data.item.competition_state_color : 'secondary' }`"
								>{{ data.item.competition_state_name || '-' }}</b-badge>
							</div>
						</template>

						<template #cell(answer)="data">
							<div class="text-nowrap">
								<b-badge
									:variant="`light-${ data.item.answer === 1 ? 'success' : data.item.answer === 0 ? 'danger' : 'secondary' }`"
								>{{ data.item.answer === 1 ? 'ACEPTADA' : data.item.answer === 0 ? 'RECHAZADA' : '-' }}</b-badge>
							</div>
						</template>

						<template #cell(comment)="data">
							<div class="text-nowrap">
								<b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon rounded-circle"
									@click="openCommentModal(data.item)"
								>
									<feather-icon icon="MessageSquareIcon" />
								</b-button>
							</div>
						</template>
					</b-table>
				</b-col>

				<b-col
					class="mx-2 mb-2"
					cols="12"
				>
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start"
						>
							<span
								class="text-muted"
							>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="currentPage"
								:total-rows="totalBidders"
								:per-page="perPage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon
										icon="ChevronLeftIcon"
										size="18"
									/>
								</template>
								<template #next-text>
									<feather-icon
										icon="ChevronRightIcon"
										size="18"
									/>
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>

		<!-- modal comment-->
		<b-modal
			ref="commentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="COMENTARIO SOBRE EL POSTOR"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<b-form-group>
						<label for="comment">
							<strong>Comentario</strong>
						</label>
						<b-form-textarea
							id="comment"
							:value="commentInput"
							placeholder="Comente aquí ..."
							rows="4"
							disabled
							readonly
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import { VBModal } from "bootstrap-vue"
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive"
import { ref, onUnmounted } from "@vue/composition-api"
import useRequestEditTabBiddersValidation from "./useRequestEditTabBiddersValidation"
import bidderValidationStoreModule from "./bidderValidationStoreModule"
import { avatarText } from "@core/utils/filter"
import store from "@/store"

export default {
	components: {
		vSelect,

		BButtonLoading
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},
	props: {
		requestId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const REQUEST_APP_STORE_MODULE_NAME = "app-request-bidder"

		// Register module
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				bidderValidationStoreModule
			)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		const commentModal = ref(null)
		const commentInput = ref(null)

		const openCommentModal = ({ comment }) => {
			commentInput.value = comment

			commentModal.value.show()
		}

		const {
			fetchBiddersValidation,
			tableColumns,
			perPage,
			currentPage,
			totalBidders,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBiddersListTable,
			refetchData
		} = useRequestEditTabBiddersValidation(props.requestId)

		return {
			fetchBiddersValidation,
			tableColumns,
			perPage,
			currentPage,
			totalBidders,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refBiddersListTable,
			refetchData,

			avatarText,

			commentInput,
			openCommentModal,
			commentModal
		}
	}
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>