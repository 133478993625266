<template>
	<section class="mt-0">
		<b-form>
			<b-row>
				<b-col md="12">
					<b-card
						footer-tag="footer"
						class="mb-0"
					>
						<b-row>
							<b-col
								cols="12"
								class="my-1"
							>
								<b-alert
									variant="info"
									show
								>
									<h6 class="alert-heading">
										<feather-icon
											icon="InfoIcon"
											class="mr-50"
										/>
										<span
											class="small"
										>La ejecución del servicio se dará acabo en la(s) unidad(es) seleccionada(s)</span>
									</h6>
								</b-alert>
							</b-col>

							<!-- TENDER TITLE -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="name">
									<strong>OBJETO DEL CONTRATO</strong>
								</label>
								<b-form-input
									id="name"
									:value="formData.title"
									disabled
									readonly
									trim
								/>
							</b-col>

							<!-- CONTRACT TYPE -->
							<b-col
								cols="12"
								md="5"
								class="mb-2"
							>
								<label for="contractType">
									<strong>TIPO DE CONTRATO</strong>
								</label>
								<v-select
									id="contractType"
									:value="formData.contract_type"
									:options="options.contractTypes"
									disabled
									readonly
								/>
							</b-col>

							<!-- LOCATION -->
							<b-col
								cols="12"
								md="4"
								class="mb-2"
							>
								<label for="location">
									<strong>EJECUCIÓN DE SERVICIO</strong>
								</label>
								<v-select
									id="location"
									:value="formData.locations"
									:style="{ color: 'black' }"
									multiple
									:options="options.locations"
									disabled
								/>
							</b-col>

							<b-col
								cols="12"
								md="3"
								class="mb-2"
							>
								<label for="tender_type">
									<strong>TIPO DE LICITACIÓN</strong>
								</label>
								<v-select
									id="tender_type"
									:value="formData.tender_type"
									:options="options.tenderTypes"
									disabled
									readonly
								/>
							</b-col>

							<!-- DESCRIPTION -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<label for="description">
									<strong>DESCRIPCIÓN</strong>
								</label>
								<b-form-textarea
									id="description"
									v-model="formData.description"
									rows="5"
									disabled
									readonly
								></b-form-textarea>
							</b-col>

							<!-- VISIT -->
							<b-col
								cols="12"
								class="mb-2"
							>
								<b-form-checkbox
									:checked="formData.enabled_visit"
									disabled
									readonly
								>HABILITAR VISITA</b-form-checkbox>
							</b-col>

							<!-- VISIT DOCUMENTS -->
							<b-col
								cols="12"
								v-if="formData.enabled_visit"
							>
								<label
									for="visitDocuments"
									class="mb-1"
								>
									<strong>DOCUMENTOS REQUERIDOS PARA LA VISITA</strong>
								</label>
								<div
									class="d-flex align-items-center mb-1"
									v-for="(document, index) in formData.documents"
									:key="index"
								>
									<b-form-checkbox
										name="documents"
										:checked="document.is_required"
										disabled
										readonly
									>{{ document.name }}</b-form-checkbox>
								</div>
							</b-col>
						</b-row>
					</b-card>
				</b-col>
			</b-row>
		</b-form>
	</section>
</template>

<script>
import { ref, onMounted, onUnmounted } from "@vue/composition-api"
import useNotifications from "@notifications"
import generalInformationStoreModule from "./generalInformationStoreModule"
import Ripple from "vue-ripple-directive"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"

export default {
	components: {
		vSelect
	},
	directives: {
		Ripple
	},
	props: {
		requestInformationId: {
			type: Number,
			required: true
		},
		visitDocuments: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	setup(props) {
		// USE TOAST
		const { toastNotification } = useNotifications()

		// REFS
		const refForm = ref(null)
		const options = ref({})
		const processing = ref(false)
		const formData = ref({
			title: null,
			description: null,
			tender_type: null,
			enabled_visit: 0,
			is_published: 0,
			documents: [],
			locations: []
		})

		const REQUEST_APP_STORE_MODULE_NAME = "app-request-info"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(
				REQUEST_APP_STORE_MODULE_NAME,
				generalInformationStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		onMounted(async () => {
			await getSelectFilters()
			getData()
		})

		const getSelectFilters = async () => {
			const response1 = await axios.get("/selectors/contract-types")
			const response2 = await axios.get("/selectors/locations")
			const tenderTypes = [
				{ label: "NORMAL", value: "NORMAL" },
				{ label: "DIRECTA", value: "DIRECTA" }
			]
			options.value = {
				...response1.data,
				...response2.data,
				tenderTypes
			}
		}

		const setData = (data) => {
			const contract_type = options.value.contractTypes.find(
				(item) => item.value === data.contract_type_id
			)
			const tender_type = options.value.tenderTypes.find(
				(item) => item.value === data.tender_type
			)

			const locationIds = data.locations.map((item) => item.value)
			const locations = options.value.locations.filter((item) =>
				locationIds.includes(item.value)
			)

			formData.value = {
				title: data.title,
				description: data.description,
				enabled_visit: data.enabled_visit,
				documents: props.visitDocuments,
				contract_type,
				tender_type,
				locations
			}
		}

		const getData = () => {
			store
				.dispatch("app-request-info/fetchRequestInformation", {
					id: props.requestInformationId
				})
				.then((response) => {
					setData(response.data.requestInformation)
				})
				.catch((error) => {
					const message = error.message || error.response.data.message
					toastNotification(message, false)
				})
		}

		return {
			// DATA
			processing,
			formData,
			options,

			// REFS
			refForm,

			// METHODS
			setData
		}
	}
}
</script>

<style lang="scss" scoped>
.description-content {
	min-height: 150px;
	overflow-y: auto;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>