
<template>
	<component :is="'div'">
		<!-- Alert: No item found -->
		<b-alert
			variant="danger"
			:show="requestData === undefined"
		>
			<h4 class="alert-heading">Error al obtener los datos de la solicitud</h4>
			<div class="alert-body">
				No se encontró ninguna solicitud con este id. Verificar en la
				<b-link
					class="alert-link"
					:to="{ name: 'requests-list'}"
				>Lista de solicitudes</b-link>para buscar otra solicitud.
			</div>
		</b-alert>

		<b-tabs
			pills
			v-model="activeTab"
			v-if="requestData"
		>
			<!-- Tab: General Information -->
			<b-tab name="information">
				<template #title>
					<feather-icon
						icon="InfoIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Información general</span>
				</template>

				<request-edit-tab-general-information
					class="mt-2 pt-75"
					:request-information-id="requestData.request_information.id"
					:visit-documents="requestData.request_visit_documents"
				/>
			</b-tab>

			<!-- Tab: Bidders -->
			<b-tab name="bidders">
				<template #title>
					<feather-icon
						icon="UsersIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Postores</span>
				</template>

				<request-edit-tab-bidders-validation
					class="mt-2 pt-75"
					:request-id="requestData.id"
				/>
			</b-tab>

			<!-- Tab: Validations -->
			<b-tab name="validations">
				<template #title>
					<feather-icon
						icon="ArchiveIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Validación Bases</span>
				</template>

				<request-edit-tab-bases-validation
					class="mt-2 pt-75"
					:request-id="requestData.id"
					:request-economic-id="requestData.request_economic_template.id"
				/>
			</b-tab>

			<!-- Tab: Approvals -->
			<b-tab name="approvals">
				<template #title>
					<feather-icon
						icon="CheckCircleIcon"
						size="16"
						class="mr-0 mr-sm-50"
					/>
					<span class="d-none d-sm-inline">Aprobaciones</span>
				</template>

				<request-edit-tab-approvals
					class="mt-2 pt-75"
					:request-id="requestData.id"
				/>
			</b-tab>
		</b-tabs>
	</component>
</template>

<script>
import RequestEditTabGeneralInformation from "./general-information/RequestEditTabGeneralInformation.vue"
import RequestEditTabBiddersValidation from "./bidders-validation/RequestEditTabBiddersValidation.vue"
import RequestEditTabBasesValidation from "./bases-validation/RequestEditTabBasesValidation.vue"
import RequestEditTabApprovals from "./approvals/RequestEditTabApprovals.vue"
import requestStoreModule from "../requestStoreModule"
import { ref, onUnmounted, computed } from "@vue/composition-api"
import useNotifications from "@notifications"
import router from "@/router"
import store from "@/store"

export default {
	components: {
		RequestEditTabGeneralInformation,
		RequestEditTabBiddersValidation,
		RequestEditTabBasesValidation,
		RequestEditTabApprovals
	},
	setup() {
		const { toastNotification } = useNotifications()

		const activeTab = computed({
			set(val) {
				let query = { ...router.currentRoute.query }
				query.tab = val
				router.replace({ query: query })
			},
			get() {
				return parseInt(router.currentRoute.query.tab)
			}
		})

		// REFS
		const requestData = ref(null)

		const REQUEST_APP_STORE_MODULE_NAME = "app-request"

		// REGISTER MODULE
		if (!store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
			store.registerModule(REQUEST_APP_STORE_MODULE_NAME, requestStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(REQUEST_APP_STORE_MODULE_NAME))
				store.unregisterModule(REQUEST_APP_STORE_MODULE_NAME)
		})

		store
			.dispatch("app-request/fetchRequest", {
				id: router.currentRoute.params.id
			})
			.then((response) => {
				requestData.value = response.data.request
			})
			.catch((error) => {
				if (error.response.status === 404) {
					requestData.value = undefined
				}
				toastNotification(error.response.data.message, false)
				router.push({ name: "requests-list" })
			})

		return {
			activeTab,
			requestData
		}
	}
}
</script>