import { ref, watch, computed } from '@vue/composition-api';
import router from "@/router"
import store from '@/store';

// Notification
import useNotifications from "@notifications"

export default function useRequestEditTabBiddersValidation(id) {
	// Use toast
	const { toastNotification } = useNotifications()

	const refBiddersListTable = ref(null);

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	const tableColumns = [
		{ key: 'bidder_name', sortable: true, label: 'POSTOR', thClass: styleColumns.thClass },
		{ key: 'bidder_email', sortable: true, label: 'CONTACTO', thClass: styleColumns.thClass },
		{ key: 'comment', sortable: false, label: 'COMENTARIO', ...styleColumns },
	];

	const perPage = ref(10);
	const totalBidders = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('id');
	const isSortDirDesc = ref(false);

	const dataMeta = computed(() => {
		const localItemsCount = refBiddersListTable.value ? refBiddersListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalBidders.value,
		}
	});

	const refetchData = () => {
		refBiddersListTable.value.refresh()
	};

	watch([currentPage, perPage, searchQuery], () => {
		refetchData()
	});

	const fetchBiddersValidation = (ctx, callback) => {

		store
			.dispatch('app-request-bidder/fetchRequestBidders', { 
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				request_id: id 
			})
			.then(response => {
				const { total, bidders } = response.data;
				callback(bidders);
				totalBidders.value = total;
			})
			.catch(() => {
				toastNotification('Error al obtener la lista de postores', false)
			})
	};

	return {
		fetchBiddersValidation,
		tableColumns,
		perPage,
		currentPage,
		totalBidders,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refBiddersListTable,

		refetchData,
	}
}