<template>
	<section>
		<b-row>
			<b-col>
				<b-card>
					<b-tabs
						content-class="mt-2"
						justified
					>
						<b-tab
							active
							title="Plantilla Económica"
						>
							<base-validation-edit-tab-economic
								:request-id="requestId"
								:request-economic-id="requestEconomicId"
								@update-description="updateDescription"
							/>
						</b-tab>

						<b-tab title="Validación de Requerimientos">
							<base-validation-edit-tab-requirements
								:request-id="requestId"
								:economic-proposal="economicData"
							/>
						</b-tab>

						<b-tab title="Validación de Documentos">
							<base-validation-edit-tab-documents :request-id="requestId" />
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import { ref } from "@vue/composition-api"
import BaseValidationEditTabEconomic from "./tab-economic/BaseValidationEditTabEconomic.vue"
import BaseValidationEditTabRequirements from "./tab-requirements/BaseValidationEditTabRequirements.vue"
import BaseValidationEditTabDocuments from "./tab-documents/BaseValidationEditTabDocuments.vue"

export default {
	components: {
		BaseValidationEditTabEconomic,
		BaseValidationEditTabRequirements,
		BaseValidationEditTabDocuments
	},
	props: {
		requestId: {
			type: Number,
			required: true
		},
		requestEconomicId: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const economicData = ref({
			id: props.requestEconomicId,
			description: null
		})

		const updateDescription = (value) => {
			economicData.value.description = value
		}

		return {
			economicData,

			updateDescription
		}
	}
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>